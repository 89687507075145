"use client";

import WaitlistForm from "./WaitListForm";

export default function CTASection() {
	return (
		<div className="mt-16 bg-gray-600 p-8 rounded-lg text-center">
			<h2 className="text-3xl font-bold text-white mb-4">
				Get Ready to Enhance Your Fire Department&apos;s Safety!
			</h2>
			<p className="text-xl text-white mb-6">
				Our software is currently under development. Be the first to know when
				we launch by signing up for our waitlist. Stay ahead in ensuring NFPA
				1500 compliance and keeping your firefighters safe.
			</p>
			<WaitlistForm />
		</div>
	);
}
