"use client";

import React, { useEffect } from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import FeatureList from "../../components/ui/FeatureList";
import Testimonials from "../../components/ui/Testimonials";
import FAQSection from "../../components/ui/FAQ";
import CTASection from "../../components/CTASection";
import { createSupabaseClient } from "../lib/databaseUtils";

export default function HomePage() {
	const supabase = createSupabaseClient();
	const router = useRouter();

	useEffect(() => {
		const attemptSilentLogin = async () => {
			const {
				data: { session },
			} = await supabase.auth.getSession();

			if (session) {
				console.log("User is authenticated, redirecting to dashboard...");
				router.push("/dashboard"); // Adjust the redirect path as needed
			} else {
				console.log("No session found, user remains on public page.");
			}
		};

		attemptSilentLogin();
	}, [router, supabase]);

	return (
		<div className="relative bg-white">
			<div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
				<div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-32 lg:pb-56 xl:col-span-6">
					<div className="mx-auto max-w-2xl lg:mx-0">
						<div className="hidden sm:flex">
							<div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
								NFPA 1500 Compliance Made Easy&nbsp;
								<a
									href="#features"
									className="whitespace-nowrap font-semibold text-red-400"
								>
									<span className="absolute inset-0" aria-hidden="true" />
									Learn how <span aria-hidden="true">&rarr;</span>
								</a>
							</div>
						</div>
						<h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
							Elevate Your Fire Department&apos;s Safety and Compliance
						</h1>
						<p className="mt-6 text-lg leading-8 text-gray-600">
							FireCompli streamlines NFPA 1500 compliance, equipment tracking,
							and personnel management. Enhance your department&apos;s safety,
							readiness, and efficiency with our comprehensive solution.
						</p>
						<div className="mt-10 flex items-center gap-x-6">
							<a
								href="/signup"
								className="rounded-md bg-red-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
							>
								Start Free Trial
							</a>
							<a
								href="#features"
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								Explore Features <span aria-hidden="true">→</span>
							</a>
						</div>
					</div>
				</div>
				<div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
					<Image
						alt="Firefighters in action"
						src="/images/fire-hero-1.webp"
						width={1000}
						height={1500}
						style={{ maxWidth: "500px", height: "auto" }}
						className="aspect-[3/2] w-full bg-gray-50 object-cover object-top lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
					/>
				</div>
			</div>

			<div id="features">
				<FeatureList />
			</div>
			<Testimonials />
			<FAQSection />
			<CTASection />
		</div>
	);
}
