"use client";

import Image from "next/image";

export default function Testimonials() {
	return (
		<section className="bg-white py-24 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
					<div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
						<Image
							alt="Cityville Fire Department Logo"
							src="/fire-logo-1.webp"
							className="h-16 self-start"
							width={64}
							height={64}
						/>
						<figure className="mt-10 flex flex-auto flex-col justify-between">
							<blockquote className="text-lg leading-8 text-gray-900">
								<p>
									&ldquo;This NFPA 1500 tracking app has revolutionized how we
									manage our health and safety program. It&apos;s user-friendly,
									comprehensive, and has significantly improved our compliance.
									The equipment tracking feature alone has saved us countless
									hours and improved our readiness. I highly recommend it to any
									fire department looking to enhance their safety
									protocols.&ldquo;
								</p>
							</blockquote>
							<figcaption className="mt-10 flex items-center gap-x-6">
								<Image
									alt="Chief Sarah Johnson"
									src="/images/fire-avatar-2.webp"
									className="h-14 w-14 rounded-full bg-gray-50"
									width={56}
									height={56}
								/>
								<div className="text-base">
									<div className="font-semibold text-gray-900">
										Chief Sarah Johnson
									</div>
									<div className="mt-1 text-gray-500">
										Cityville Fire Department
									</div>
								</div>
							</figcaption>
						</figure>
					</div>
					<div className="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
						<Image
							alt="Mountainview Fire & Rescue Logo"
							src="/images/fire-logo-2.webp"
							className="h-16 self-start"
							width={64}
							height={64}
						/>
						<figure className="mt-10 flex flex-auto flex-col justify-between">
							<blockquote className="text-lg leading-8 text-gray-900">
								<p>
									&ldquo;As a Safety Officer, I can&apos;t overstate how
									valuable this app has been for our department. The ability to
									track staff training, medical evaluations, and equipment
									inspections all in one place has streamlined our operations.
									The real-time alerts for upcoming expirations and required
									maintenance have been a game-changer for keeping our team safe
									and compliant.&ldquo;
								</p>
							</blockquote>
							<figcaption className="mt-10 flex items-center gap-x-6">
								<Image
									alt="Lieutenant Mark Rodriguez"
									src="/images/fire-avatar-1.webp"
									className="h-14 w-14 rounded-full bg-gray-50"
									width={56}
									height={56}
								/>
								<div className="text-base">
									<div className="font-semibold text-gray-900">
										Lieutenant Mark Rodriguez
									</div>
									<div className="mt-1 text-gray-500">
										Safety Officer, Mountainview Fire & Rescue
									</div>
								</div>
							</figcaption>
						</figure>
					</div>
				</div>
			</div>
		</section>
	);
}
