"use client";

import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
	{
		question: "What is NFPA 1500 and why is it important?",
		answer:
			"NFPA 1500 is the Standard on Fire Department Occupational Safety, Health, and Wellness Program. It's crucial because it establishes minimum requirements for a fire department's occupational safety and health program, helping to reduce the risk of injury, illness, and fatality to fire department members.",
	},
	{
		question: "How does FireCompli help with NFPA 1500 compliance?",
		answer:
			"FireCompli streamlines NFPA 1500 compliance by providing tools for tracking equipment maintenance, personnel training, health assessments, and incident reporting. It offers real-time alerts for upcoming expirations and required actions, ensuring your department stays compliant and safe.",
	},
	{
		question: "Can FireCompli integrate with our existing systems?",
		answer:
			"Yes, FireCompli is designed to integrate with many common fire department management systems. We offer API access and custom integration solutions to ensure seamless data flow between FireCompli and your existing tools.",
	},
	{
		question:
			"Is FireCompli suitable for both career and volunteer fire departments?",
		answer:
			"Absolutely. FireCompli is scalable and customizable to meet the needs of both career and volunteer fire departments. Our system can handle the complexities of larger career departments while remaining user-friendly for volunteer organizations.",
	},
	{
		question: "How secure is our department's data with FireCompli?",
		answer:
			"We take data security very seriously. FireCompli uses industry-standard encryption, regular security audits, and strict access controls. We are compliant with relevant data protection regulations and never share your department's data with third parties.",
	},
];

export default function FAQ() {
	return (
		<div className="bg-white">
			<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
				<div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
					<h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
						Frequently Asked Questions
					</h2>
					<dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
						{faqs.map((faq) => (
							<Disclosure as="div" key={faq.question} className="pt-6">
								{({ open }) => (
									<>
										<dt>
											<Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
												<span className="text-base font-semibold leading-7">
													{faq.question}
												</span>
												<span className="ml-6 flex h-7 items-center">
													{open ? (
														<MinusSmallIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													) : (
														<PlusSmallIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													)}
												</span>
											</Disclosure.Button>
										</dt>
										<Disclosure.Panel as="dd" className="mt-2 pr-12">
											<p className="text-base leading-7 text-gray-600">
												{faq.answer}
											</p>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
